import { Box, Collapse, Button, TextField } from "@mui/material";
import { IDailyChart } from "../../../app/models/dailysheet";
import { ReactNode, useMemo, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DynamicBarShowChartDialog from "./DynamicBarShowChartDialog";
import DailyProductionsGrid from "./DailyProductionsGrid";
import DailyInjectionsGrid from "./DailyInjectionsGrid";
import DailyMonitoringGrid from "./DailyMonitoringGrid";
import { observer } from "mobx-react-lite";
import { DAILY_SHEET, DAILY_SHEET_PRODUCTIONS } from "../../../app/config/enum";
import { useStore } from "../../../app/stores/store";

type TDynamicAccordionProps = {
  identifier: string;
  index: number;
};

function DynamicAccordion({ identifier, index }: TDynamicAccordionProps) {
  const { setManualDailySheetDataProductions, dailySheetData } = useStore().dailysheetStore;
  const [openChartDialog, setOpenChartDialog] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const title = useMemo(() => identifier, [identifier]);

  const dynamicData = useMemo(() => {
    let name: string | undefined = "";
    let chart: IDailyChart[] | undefined = [];
    let gridData: ReactNode = <></>;

    if (identifier === DAILY_SHEET.PRODUCTIONS && dailySheetData?.productions) {
      name = dailySheetData?.productions?.[index].locationName;
      chart = dailySheetData?.productions?.[index].charts;
      gridData = <DailyProductionsGrid index={index} />;
    }
    if (identifier === DAILY_SHEET.INJECTIONS && dailySheetData?.injections) {
      name = dailySheetData?.injections?.[index].locationName;
      chart = dailySheetData?.injections?.[index].charts;
      gridData = <DailyInjectionsGrid index={index} />;
    }
    if (identifier === DAILY_SHEET.MONITORINGS && dailySheetData?.monitoring) {
      name = "";
      chart = dailySheetData?.monitoring?.charts;
      gridData = <DailyMonitoringGrid data={dailySheetData?.monitoring?.table ?? []} />;
    }

    return { name, chart, gridData };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier]);

  return (
    <Box
      sx={{
        p: "16px",
        cursor: "pointer",
        borderRadius: "4px",
        border: "1px solid #DEDEDE",
        background: "#FFF",
        mb: "12px",
      }}
    >
      <Box onClick={() => setIsOpen(!isOpen)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Box sx={{ fontWeight: "bold", color: "#212121" }}>{`${title} ${identifier === "Monitoring" ? "" : index + 1}`}</Box>
          <Box sx={{ fontSize: "12px" }}>{dynamicData.name}</Box>
        </Box>
        <Box>{isOpen ? <ExpandLess /> : <ExpandMore />}</Box>
      </Box>
      <Collapse sx={{ mt: "24px" }} in={isOpen} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "16px" }}>
          <Button variant="contained" color="primary" type="submit" onClick={() => setOpenChartDialog(true)}>
            Open Chart
          </Button>
        </Box>
        {identifier === DAILY_SHEET.PRODUCTIONS && dailySheetData?.productions ? (
          <Box sx={{ display: "flex", gap: "6px", mb: "12px" }}>
            <TextField
              name="brineRate"
              type="number"
              value={dailySheetData?.productions?.[index].brineRate}
              label={`Brine Rate (Prev - ${dailySheetData?.productions?.[index].brineRatePrev ?? 0})`}
              onChange={(e) => setManualDailySheetDataProductions(DAILY_SHEET_PRODUCTIONS.BRINE_RATE, index, Number(e.target.value))}
              fullWidth
            />
            <TextField
              name="inletPress"
              type="number"
              value={dailySheetData?.productions?.[index].inletPress}
              label={`Inlet Press. (Prev - ${dailySheetData?.productions?.[index].inletPressPrev ?? 0})`}
              onChange={(e) => setManualDailySheetDataProductions(DAILY_SHEET_PRODUCTIONS.INLET_PRESS, index, Number(e.target.value))}
              fullWidth
            />
            <TextField
              name="inletTemp"
              type="number"
              value={dailySheetData?.productions?.[index].inletTemp}
              label={`Inlet Temp. (Prev - ${dailySheetData?.productions?.[index].inletTempPrev ?? 0})`}
              onChange={(e) => setManualDailySheetDataProductions(DAILY_SHEET_PRODUCTIONS.INLET_TEMP, index, Number(e.target.value))}
              fullWidth
            />
            <TextField
              name="steamRate"
              type="number"
              value={dailySheetData?.productions?.[index].steamRate}
              label={`Steam Rate (Prev - ${dailySheetData?.productions?.[index].steamRatePrev ?? 0})`}
              onChange={(e) => setManualDailySheetDataProductions(DAILY_SHEET_PRODUCTIONS.STEAM_RATE, index, Number(e.target.value))}
              fullWidth
            />
          </Box>
        ) : undefined}
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <Box sx={{ width: 2100, overflowX: "auto", height: "auto", display: "flex", flexDirection: "row", justifyContent: "space-beetwen" }}>
            <Box sx={{ width: "100%" }}>{dynamicData.gridData}</Box>
          </Box>
        </Box>
      </Collapse>
      {openChartDialog ? (
        <DynamicBarShowChartDialog series={dynamicData.chart} openChartDialog={openChartDialog} setOpenChartDialog={setOpenChartDialog} />
      ) : undefined}
    </Box>
  );
}

export default observer(DynamicAccordion);
