import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import RkdCard from "../../app/component/card/RkdCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
  Divider,
} from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { IQueryRequest } from "../../app/models/query";
import QueryCharts from "./component/QueryCharts";

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function QueryPageIndex() {
  const { getObjectList, objectList, getFieldList, fieldList, getQueryChart, loadingGetSelector } = useStore().queryStore;
  const [selectedMultipleObject, setSelectedMultipleObject] = useState<string[]>([]);
  const [displaySelectedObject, setDisplaySelectedObject] = useState<string[]>([]);
  const [selectedMultipleField, setSelectedMultipleField] = useState<string[]>([]);
  const [displaySelectedField, setDisplaySelectedField] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const handleLoadData = () => {
    if (!selectedMultipleObject.length || !selectedMultipleField.length) return;

    const data: IQueryRequest = {
      startDate: startDate,
      endDate: endDate,
      objects: selectedMultipleObject.map((item, index) => ({
        value: item,
        text: displaySelectedObject[index],
      })),
      fields: selectedMultipleField.map((item, index) => ({
        value: item,
        text: displaySelectedField[index],
      })),
    };
    getQueryChart(data);
  };

  const handleSelectObject = async (event: SelectChangeEvent<typeof selectedMultipleObject>, identifier: string) => {
    const { value } = event.target;
    let result: string[] = [];

    if (identifier === "objectSelector") {
      objectList.forEach((val) => value.includes(val.value) && result.push(val.text));
      setDisplaySelectedObject(result);
      setSelectedMultipleObject(typeof value === "string" ? value.split(",") : value);
    } else {
      fieldList.forEach((val) => value.includes(val.value) && result.push(val.text));
      setDisplaySelectedField(result);
      setSelectedMultipleField(typeof value === "string" ? value.split(",") : value);
    }
  };

  // COMPONENT DID MOUNT
  useEffect(() => {
    getObjectList();
    getFieldList();
  }, [getFieldList, getObjectList]);

  return (
    <RkdCard title="Query Reporting" subtitle="Add Edit & Delete Query Reporting from this Page">
      {loadingGetSelector ? (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                onChange={(e: any) => setStartDate(e)}
                value={startDate}
                inputFormat="dd MMM yyyy"
                label="Start Date"
                renderInput={(props) => <TextField label="Start Date" {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                onChange={(e: any) => setEndDate(e)}
                value={endDate}
                inputFormat="dd MMM yyyy"
                label="End Date"
                renderInput={(props) => <TextField label="End Date" {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Object Selector</InputLabel>
              <Select
                multiple
                value={selectedMultipleObject}
                onChange={(e) => handleSelectObject(e, "objectSelector")}
                input={<OutlinedInput label="Object Selector" />}
                renderValue={() => displaySelectedObject.join(", ")}
                MenuProps={MenuProps}
              >
                {objectList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Checkbox checked={selectedMultipleObject.indexOf(item.value) > -1} />
                    <ListItemText primary={item.text} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Field Selector</InputLabel>
              <Select
                multiple
                onChange={(e) => handleSelectObject(e, "fieldSelector")}
                value={selectedMultipleField}
                input={<OutlinedInput label="Field Selector" />}
                renderValue={() => displaySelectedField.join(", ")}
                MenuProps={MenuProps}
              >
                {fieldList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Checkbox checked={selectedMultipleField.indexOf(item.value) > -1} />
                    <ListItemText primary={item.text} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 1 }}>
            <Box sx={{ height: "100%" }}>
              <Button fullWidth sx={{ height: "100%" }} variant="contained" color="success" onClick={() => handleLoadData()}>
                Load Data
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mb: "12px" }}>
            <Divider sx={{ my: "24px" }} />
            <Box sx={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "16px" }}>Charts</Box>
          </Grid>
          <Grid item xs={12}>
            <QueryCharts />
          </Grid>
        </Grid>
      )}
    </RkdCard>
  );
}

export default observer(QueryPageIndex);
